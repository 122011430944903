export const insights = {
    "structure": [
        {
            "view": "config",
            "props": {
                "logo_url": "img/logo.png",
                "splash": "stb-anim-3.mp4",
                "start_label": "Start",
                "play_again": "Finish",
                "display_name": "Spot the bot",
                "continue_label": "Continue",
                "how_to_label": "How to play?",
                "results_label": "Results summary",
                "winner_label": "the <span>Winner</span> will be announced on our social channels<span>.</span>",
                "image_to_show": 5,
                "real_image_to_show": 3,
                "ai_image_to_show": 2,
                "score_labels":{
                    "1": "Good try",
                    "2": "Good try",
                    "3": "Well done",
                    "4": "Well done",
                    "5": "You're an AI genius",
                    "Prize": "Speak to us about our prize draw."
                }
            },
        },
        {
            "view": "how_to_content",
            "props":{
                "logo_url": "img/logo.png",
                "title": "How to play",
                "play_btn": "Play now",
                "steps": [
                    {
                        "image": "img/step-1.png",
                        "text": "Look at the image and decide if it's <b>real or if it's been AI-generated or modified.</b>"
                    },
                    {
                        "image": "img/step-2.png",
                        "text": "<b>Find out if you were correct</b>, and get ready for the next image."
                    },
                    {
                        "image": "img/step-3.png",
                        "text": "Check if you're an AI whizz and see if you qualify for our prize draw. <br/><br/><b>Winners will be announced on Cavendish social media channels.</b>"
                    }
                ]
            },
        },
        {
            "view": "steps",
            "props": {
                "steps": [
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "0",
                        "image": "img/quiz/AdobeStock_588781863.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "1",
                        "image": "img/quiz/AdobeStock_624219594.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "2",
                        "image": "img/quiz/AdobeStock_624315512.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "3",
                        "image": "img/quiz/AdobeStock_776248296.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "4",
                        "image": "img/quiz/AdobeStock_779362412.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "5",
                        "image": "img/quiz/AdobeStock_795765425.jpg",
                        "is_ai": true,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "6",
                        "image": "img/quiz/AdobeStock_211363011.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "7",
                        "image": "img/quiz/AdobeStock_249745308.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "8",
                        "image": "img/quiz/AdobeStock_256148306.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "9",
                        "image": "img/quiz/AdobeStock_294233191.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "10",
                        "image": "img/quiz/AdobeStock_296053090.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "11",
                        "image": "img/quiz/AdobeStock_339758916.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "12",
                        "image": "img/quiz/AdobeStock_471708339.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "13",
                        "image": "img/quiz/AdobeStock_666054491.jpg",
                        "is_ai": false,
                        "caption": "",
                    },
                    {
                        "step": "question",
                        "type": "spot_bot",
                        "key": "14",
                        "image": "img/quiz/AdobeStock_773254583.jpg",
                        "is_ai": false,
                        "caption": "",
                    }
                ]
            }
        }
    ],
    "actions": {
        data(data){
            //calculate score
            let score = 0;
            let dataArray = Object.values(data);
            dataArray.forEach(function(r){
                if(r){
                    score++;
                }
            });
            //save scores
            const scoresArrayString = localStorage.getItem('scores');
            const scoreArray = JSON.parse(scoresArrayString) || []; //Initialise empty array if not found
            const now = Date.now();
            scoreArray.push({
                "score": score,
                "time": now
            })

            const toStoreScores = JSON.stringify(scoreArray);

            //save in LocalStorage
            localStorage.setItem('scores', toStoreScores);
            // console.log("Score saved", data);
        },
        interaction(type, label, target){
            // console.log("Interaction", type, label, target)
        },
        progress(type, label, target){
            // console.log("progress", type, label, target)
        },
    }
}
