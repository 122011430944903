import React, { useEffect } from 'react';
import { useState } from 'react';
import App from '../App';
import { insights } from '../data/Game';
import FeedbackPopUp from './FeedbackPopUp';
import { useNavigate, useParams } from 'react-router-dom';

export default function IntroPage () {
	function shuffleArray (arr) {
		return arr.sort(() => Math.random() - 0.5);
	}

	// Set theInsights as the data.js by default.
	let theInsights = insights;
	// If defined in the index.html as window.insights then load from there
	if (window.insights) {
		theInsights = window.insights;
	}


	// to show the questions
	const [show, setShow] = useState(false);
	const [stepQuestion, setStepQuestion] = useState('steps');
	const [feedbackUse, setFeedbackUse] = useState(false);

	// submit progress
	const handleProgressSubmit = (questionNumber) => {
		theInsights ? theInsights.actions.progress(questionNumber) : console.log('no insights data');
	};

	useEffect(() => {
		handleProgressSubmit('Welcome');
	}, []);

	// Shuffle images
	useEffect(() => {
		theInsights.structure.forEach((obj) => {
			if (obj.view === 'steps') {
				setStepQuestion(obj.view);
				obj.props.steps = shuffleArray(obj.props.steps);
				// After shuffling filter to a specific number of real and AI images
				const realImages = theInsights.structure[0].props.real_image_to_show;
				const aiImages = theInsights.structure[0].props.ai_image_to_show;

				let realSteps = obj.props.steps.filter(function (image) {
					return image.is_ai === false;
				});
				let limitedRealSteps = realSteps.slice(0, realImages);
				let otherRealSteps = realSteps.slice(realImages);
				// console.log({realSteps});
				// console.log({limitedRealSteps});
				// console.log({otherRealSteps});

				let aiSteps = obj.props.steps.filter(function (image) {
					return image.is_ai === true;
				});
				let limitedAiSteps = aiSteps.slice(0, aiImages);
				let otherAiSteps = aiSteps.slice(aiImages);
				// console.log({aiSteps});
				// console.log({limitedAiSteps});
				// console.log({otherAiSteps});

				// Then get them back into 2 arrays, the first 5 of 2 + 3, and all the rest
				let mergedArray = limitedRealSteps.concat(limitedAiSteps);
				let mergedOthers = otherRealSteps.concat(otherAiSteps);
				// Then shuffle the first 5
				mergedArray = shuffleArray(mergedArray)
				// Then reset the obj.props as a the first 5 + the others
				obj.props.steps = mergedArray.concat(mergedOthers);
			}
		});
	}, [show]);

	const handleShowClick = () => {
		setShow(true);
	};

	// Add the interaction url click
	document.addEventListener('click', function (event) {
		// If it's a link that's been clicked
		if (event.target.matches('a')) {
			// Check some attributes
			var currentHost = window.location.hostname;
			var targetHost = event.target.hostname;
			var linkUrl = event.target.href;
			var linkText = event.target.innerText; // The text of the <a> by default.
			// console.log('link click!');
			// interaction('url click', linkUrl, linkText);
			insights.actions.interaction('url click', linkUrl, linkText);
		}
	});

	// Get the size of the image...
	// https://stackoverflow.com/a/11442850
	const getMeta = async (url) => {
		const img = new Image();
		img.src = url;
		await img.decode();
		return img;
	};

	// pop function to open feedback pop up
	const feedbackPop = () => {
		setFeedbackUse(true);
		if (setFeedbackUse) {
			const element = document.getElementsByClassName('modal')[0];
			element.classList.add('modal-popup');
			// const IntroHeader = document.getElementsByClassName('intro_header')[0];
			// IntroHeader.style.display = 'none';
		}
	};

	var config;
	var howTo;
	theInsights.structure.map(function(e){
		if(e.view == 'config'){
			config = e;
		}
		if(e.view == 'how_to_content'){
			howTo = e;
		}
	});
	
	return (
		<>
			{!show && (
				<div className="survey">
					<div className="survey__panel survey__panel--full modal">
						{(config.props.splash)?(
							<video autoPlay loop muted id="video-splash" width="1080" height="1920">
								<source src={config.props.splash}/>
							</video>
						):''}
						<div className="survey__intro" key="spot-the-bot">
							<div className="survey__logo">
								<img src={config.props.logo_url} alt=""/>
							</div>
						</div>
						<div className="survey__cta">
							<button onClick={handleShowClick}>{config.props.start_label}</button>
							{!feedbackUse && (<button className="open_popup plain" onClick={feedbackPop}>
								<svg id="icon-question" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.94 40.94">
								<path id="icon-question-path" d="M40.94,20.47c0,11.3-9.16,20.47-20.47,20.47S0,31.77,0,20.47,9.16,0,20.47,0c0,0,0,0,0,0,11.3,0,20.47,9.16,20.47,20.47h0ZM21.02,6.77c-3.93-.15-7.62,1.87-9.62,5.26-.29.44-.19,1.02.22,1.34l2.86,2.17c.43.33,1.04.25,1.38-.18,1.47-1.87,2.49-2.95,4.73-2.95,1.69,0,3.77,1.09,3.77,2.72,0,1.24-1.02,1.87-2.68,2.8-1.94,1.09-4.51,2.44-4.51,5.83v.33c0,.55.44.99.99.99h4.62c.55,0,.99-.44.99-.99v-.11c0-.86.92-1.41,2.35-2.24,1.75-1,4.52-2.52,4.52-6.56,0-4.79-4.97-8.42-9.62-8.42ZM20.47,27.24c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8,3.8-1.7,3.8-3.8h0c0-2.1-1.7-3.8-3.8-3.8Z" fill="currentColor" strokeWidth="0"/>
								</svg>
								{config.props.how_to_label}</button>)}
							{feedbackUse && (<FeedbackPopUp setFeedbackUse={setFeedbackUse} handleShowClick={handleShowClick} howTo={howTo} setShow={setShow}/>)}
						</div>
					</div>
				</div>
			)}
			{show && (<App theInsights={theInsights} stepQuestion={stepQuestion} setShow={setShow} handleProgressSubmit={handleProgressSubmit}/>)}
		</>
	);
}
