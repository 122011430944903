import React, { useEffect, useState } from 'react';

export default function ScorePercent ({scr, max}) {
    //calculate score summary
    const scoresArrayString = localStorage.getItem('scores');
    const scoresArray = JSON.parse(scoresArrayString) || []; //Initialise empty array if not found
    const [width, setWidth] = useState('00.0%'); // Initialize width state
    let q = 0;

    if(scoresArray.length > 0){
        scoresArray.map(function(el, i){
            if(typeof scr !== 'undefined' && parseInt(el.score) == parseInt(scr)){
                q++;
            }
            if(typeof max !== 'undefined' && parseInt(el.score) <= parseInt(max)){
                q++;
            }
        })
    }

    useEffect(() => {
        const newWidth = (q > 0 ? ((q/scoresArray.length)*100).toFixed(1) : '0.0') + '%';
        const timeoutId = setTimeout(() => setWidth(newWidth), 500); // Set width after 1 second
        return () => clearTimeout(timeoutId); // Clear timeout on component unmount
    }, [q, scoresArray.length]);
    
    return(
        <>
        <div className='bar-chart'>
            <div className='bar-value' style={{width: width}}></div>
        </div>
        <span className='score-percent'>
            {width}
        </span>
        </>
    ) 
}