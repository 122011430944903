import React, { useEffect } from 'react';
import { useState } from 'react';

export default function SpotBot ({ data, currentQuestion, responses, choicesSelected, setChoicesSelected, maxImages }) {
	let min = 1;
	const max = 1;
	let answers = [];
	const [ansValue, setAnsValue] = useState('');

	const handleChoiceChange = (evt, is_ai) => {
		const { value, checked } = evt.target;
		const { choices } = choicesSelected;
		
		let res = value == data[currentQuestion].is_ai?true:false;
		setAnsValue(value);
		setChoicesSelected({
			choices: res,
		});
	};

	useEffect(() => {
		// Work out if there are any saved options
		if (responses[currentQuestion]) {
			if (Object.values(responses[currentQuestion]) == '') {
				// If there's nothing saved set as a blank array
				setChoicesSelected({
					choices: [],
				});
			} else {
				// Otherwise set to the array saved in responses
				setChoicesSelected({
					choices: Object.values(responses[currentQuestion])[0],
				});
			}
		}
	}, [responses[currentQuestion]]);

	return (
		<>
			<div className="survey__panel question--spot-bot">
				<div className="question__question">
					<legend className='question__label' key={[currentQuestion + 1]}>
						<img key={`img` + [currentQuestion + 1]} src={data[currentQuestion].image}/>
					</legend>
					{ ansValue === '' && (<div className='buttons'>
						<div key={[currentQuestion + 1] + `-it-is-real`} className="toggle">
							<input key="it-is-real"
								type={max === 1 ? 'radio' : 'checkbox'}
								value="0"
								id="it-is-real"
								name={'question' + [currentQuestion + 1]}
								className="toggle__input"
								onChange={(evt) => { handleChoiceChange(evt, data[currentQuestion].is_ai); }}
							/>
							<label
								htmlFor="it-is-real"
								className={`toggle__label ${choicesSelected.choices.some((x) => x === answers.value) ? 'checked-option' : ''}`}
							>
								<span>It&rsquo;s real</span>
							</label>
						</div>
						<div key={[currentQuestion + 1] + `-it-is-ai`} className="toggle">
							<input key="it-is-ai"
								type={max === 1 ? 'radio' : 'checkbox'}
								value="1"
								id="it-is-ai"
								name={'question' + [currentQuestion + 1]}
								className="toggle__input"
								onChange={(evt) => { handleChoiceChange(evt, data[currentQuestion].is_ai); }}
							/>
							<label
								htmlFor="it-is-ai"
								className={`toggle__label ${choicesSelected.choices.some((x) => x === answers.value) ? 'checked-option' : ''}`}
							>
								<span>It&rsquo;s AI</span>
							</label>
						</div>
					</div>)}
					{
						ansValue !== '' && (
						<div className='result'>
							<p className='medium-font'>
								<span>{ansValue == data[currentQuestion].is_ai ?"Correct" : "Incorrect"}</span> - 
								{data[currentQuestion].is_ai ?" This image is AI-generated or modified." : " This image is real."}
							</p>
							<p>{(data[currentQuestion].caption)?data[currentQuestion].caption:''}</p>
						</div>
						)
					}
				</div>
			</div>
		</>
	);
}
