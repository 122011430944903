// JavaScript code using React hooks
import React, { useState, useEffect } from 'react';

const ProgressBar = ({ progress, steps, responses }) => {
	const responsesArray = Object.values(responses);
	var cur = (progress > 0)? progress*(steps-1)/100:0;
	return (
		<>
		<div className="progress" style={{ width: `${progress}%` }} ></div>
		{
			Array.apply(null, { length: steps}).map((_, i)=>(
				<span key={`step-`+ i} className={(typeof responsesArray[i] !== 'undefined'?(responsesArray[i]?'step correct':'step incorrect'):'step') + ((i==cur)?' current':'')}></span>
			))
		}
		</>
	);
};

export default ProgressBar;
