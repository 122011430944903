import React, { useEffect } from 'react';
import ScorePercent from './ScorePercent';

export default function Summary ({ insights, handleProgressSubmit, setCurrentQuestion, setRestart, setResponses }) {
	useEffect(() => {
		handleProgressSubmit('Thank you');
	}, []);

	const handleRestart = () =>{
		setRestart(false);
		setCurrentQuestion(0);
		setResponses([]);
	}

	return (
		<>
			<div className="survey">
				<div className="survey__panel survey__panel--full suvey__thanks">
					<div className="survey__review">
					<img className='header_img' src={insights.structure[0].props.logo_url} alt="test"/>
						<ListIcon/>
						<h2>{insights.structure[0].props.results_label}<span>.</span></h2>
						<div className='scores-table'>
							<div className="scores-row">
								<span className="score-label"><b>5</b> out of <b>5</b></span><ScorePercent scr="5"/>
							</div>
							<div className="scores-row">
								<span className="score-label"><b>4</b> out of <b>5</b></span><ScorePercent scr="4"/>
							</div>
							<div className="scores-row">
								<span className="score-label"><b>3</b> out of <b>5 or less</b></span><ScorePercent max="3"/>
							</div>
						</div>
					</div>
				</div>
				<div className="survey__footer">
					<button onClick={handleRestart}>{insights.structure[0].props.play_again}</button>
				</div>
			</div>
		</>
	);
}

function ListIcon(){
	return (
		<div className="results-icon">
		<svg xmlns="http://www.w3.org/2000/svg" width="231.668" height="231.671" viewBox="0 0 231.668 231.671">
		<g transform="translate(-424.166 -271.331)">
			<path d="M231.668,115.835A115.834,115.834,0,1,1,115.833,0,115.835,115.835,0,0,1,231.668,115.835" transform="translate(424.166 271.331)" fill="#005f85"/>
			<path d="M68.92,70.945c-40.688,0-47.341-47.576-47.405-48.057a4,4,0,0,1,3.955-4.5h30.8v7.979H30.292c2.5,10.713,11.357,36.605,38.629,36.605Z" transform="translate(457.57 299.918)" fill="#101921"/>
			<path d="M51.053,70.945V62.968c27.269,0,36.13-25.887,38.629-36.605H63.7V18.384H94.5a4,4,0,0,1,3.955,4.5c-.064.48-6.717,48.057-47.405,48.057" transform="translate(503.552 299.918)" fill="#101921"/>
			<path d="M105.776,23.765H34.755a5.1,5.1,0,1,1,0-10.194h71.021a5.1,5.1,0,0,1,0,10.194" transform="translate(470.283 292.433)" fill="#101921"/>
			<rect width="25.041" height="11.081" transform="translate(528.026 376.36)" fill="#101921"/>
			<path d="M79.733,77.725H54.666C29.073,52.132,31.7,17.56,31.7,17.56H102.7s2.629,34.572-22.964,60.165" transform="translate(473.347 298.636)" fill="#ff5964"/>
			<path d="M90.806,66.94C86.6,50.984,75.18,45.445,75.18,45.445H50.166S38.748,50.984,34.54,66.94Z" transform="translate(477.875 341.997)" fill="#ff5964"/>
			<path d="M105.7,59.947v5.876H30.915V59.947a6.084,6.084,0,0,1,6.076-6.089H99.616a6.087,6.087,0,0,1,6.089,6.089" transform="translate(472.238 355.079)" fill="#101921"/>
			<path d="M126.824,66.612V98.654H25.777V66.612a8.074,8.074,0,0,1,8.069-8.071h84.907a8.075,8.075,0,0,1,8.071,8.071" transform="translate(464.249 362.361)" fill="#ff5964"/>
			<path d="M103,65.707V82.314a4.216,4.216,0,0,1-4.216,4.213h-63a4.216,4.216,0,0,1-4.213-4.213V65.707a4.216,4.216,0,0,1,4.213-4.216h63A4.217,4.217,0,0,1,103,65.707" transform="translate(473.264 366.948)" fill="#f9f9f2"/>
			<path d="M146.04,86.313H21.1A12.077,12.077,0,0,1,33.171,74.241h100.8A12.072,12.072,0,0,1,146.04,86.313" transform="translate(456.974 386.774)" fill="#101921"/>
			<path d="M67.045,60.5H62.472a1.758,1.758,0,1,1,0-3.516h4.573a1.758,1.758,0,0,1,0,3.516" transform="translate(518.575 359.94)" fill="#f9f9f2"/>
			<path d="M84.456,60.5H79.882a1.758,1.758,0,1,1,0-3.516h4.573a1.758,1.758,0,0,1,0,3.516" transform="translate(545.644 359.94)" fill="#f9f9f2"/>
			<path d="M80.845,69.27a1.753,1.753,0,0,1-1.244-.516L76.349,65.5a1.758,1.758,0,0,1,2.486-2.486l3.255,3.253a1.759,1.759,0,0,1-1.244,3" transform="translate(542.085 368.518)" fill="#f9f9f2"/>
			<path d="M68.518,56.943a1.753,1.753,0,0,1-1.244-.516L64.042,53.2a1.76,1.76,0,0,1,2.489-2.489l3.232,3.232a1.759,1.759,0,0,1-1.244,3" transform="translate(522.951 349.383)" fill="#f9f9f2"/>
			<path d="M72.076,55.47a1.758,1.758,0,0,1-1.758-1.758V49.139a1.758,1.758,0,1,1,3.516,0v4.573a1.758,1.758,0,0,1-1.758,1.758" transform="translate(533.509 345.008)" fill="#f9f9f2"/>
			<path d="M72.076,72.9a1.758,1.758,0,0,1-1.758-1.758v-4.6a1.758,1.758,0,0,1,3.516,0v4.6A1.758,1.758,0,0,1,72.076,72.9" transform="translate(533.509 372.077)" fill="#f9f9f2"/>
			<path d="M77.592,56.964a1.759,1.759,0,0,1-1.244-3L79.6,50.71A1.758,1.758,0,0,1,82.089,53.2l-3.252,3.252a1.753,1.753,0,0,1-1.244.516" transform="translate(542.086 349.382)" fill="#f9f9f2"/>
			<path d="M65.286,69.27a1.759,1.759,0,0,1-1.244-3L67.3,63.016A1.758,1.758,0,0,1,69.783,65.5L66.53,68.754a1.753,1.753,0,0,1-1.244.516" transform="translate(522.951 368.518)" fill="#f9f9f2"/>
			<path d="M18.758,36.5H14.185a1.758,1.758,0,1,1,0-3.516h4.573a1.758,1.758,0,1,1,0,3.516" transform="translate(443.49 322.622)" fill="#f9f9f2"/>
			<path d="M36.167,36.5H31.594a1.758,1.758,0,0,1,0-3.516h4.573a1.758,1.758,0,1,1,0,3.516" transform="translate(470.56 322.622)" fill="#f9f9f2"/>
			<path d="M32.558,45.271a1.753,1.753,0,0,1-1.244-.516L28.062,41.5a1.758,1.758,0,1,1,2.486-2.486L33.8,42.269a1.759,1.759,0,0,1-1.244,3" transform="translate(467 331.2)" fill="#f9f9f2"/>
			<path d="M20.231,32.945a1.753,1.753,0,0,1-1.244-.516L15.755,29.2a1.759,1.759,0,1,1,2.489-2.486l3.232,3.232a1.759,1.759,0,0,1-1.244,3" transform="translate(447.865 312.064)" fill="#f9f9f2"/>
			<path d="M23.789,31.471a1.758,1.758,0,0,1-1.758-1.758V25.14a1.758,1.758,0,0,1,3.516,0v4.573a1.758,1.758,0,0,1-1.758,1.758" transform="translate(458.424 307.689)" fill="#f9f9f2"/>
			<path d="M23.789,48.9a1.758,1.758,0,0,1-1.758-1.758v-4.6a1.758,1.758,0,1,1,3.516,0v4.6A1.758,1.758,0,0,1,23.789,48.9" transform="translate(458.424 334.759)" fill="#f9f9f2"/>
			<path d="M29.3,32.965a1.758,1.758,0,0,1-1.244-3l3.255-3.252A1.758,1.758,0,1,1,33.8,29.2l-3.252,3.255a1.761,1.761,0,0,1-1.244.514" transform="translate(467.001 312.064)" fill="#f9f9f2"/>
			<path d="M17,45.271a1.759,1.759,0,0,1-1.244-3l3.255-3.253A1.758,1.758,0,1,1,21.5,41.5l-3.252,3.253A1.753,1.753,0,0,1,17,45.271" transform="translate(447.865 331.2)" fill="#f9f9f2"/>
			<rect width="231.668" height="231.668" transform="translate(424.166 271.332)" fill="none"/>
		</g>
		</svg>
		</div>
	)
}