import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Link } from 'react-router-dom';

export default function ({ setFeedbackUse, howTo, handleShowClick }) { // [TODO] function need name
	const closefeedbackPop = () => {
		setFeedbackUse(false);
		// const IntroHeader = document.getElementsByClassName('intro_header')[0];
		// IntroHeader.style.display = 'block';
		const element = document.getElementsByClassName('modal')[0];
		element.classList.remove('modal-popup');
	};

	const playGame = () =>{
		closefeedbackPop();
		handleShowClick();
	}

	return (
		<div className="popup">
			<button className="close_popup plain" onClick={closefeedbackPop}>Close</button>
			<div className="popup_content">
				<img className='header_img' src={howTo.props.logo_url} alt="test"/>
				<h2>{howTo.props.title}<span>.</span></h2>
				<ul>
				{howTo.props.steps.map(function(st, i){
					return (
						<li key={`how-to-` + i}>
							<img src={st.image} alt=""/>
							<Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{st.text}</Markdown>
						</li>
					)
				})}
				</ul>
				<button onClick={playGame}>{howTo.props.play_btn}</button>
			</div>
		</div>
	);
}
