import './App.scss';
import { useEffect, useState } from 'react';
import SpotBot from './components/SpotBot';
import ProgressBar from './components/ProgressBar';
import SubmitPage from './components/SubmitPage';

function App ({ theInsights, reset, setShow, handleProgressSubmit, stepQuestion }) {
	// Set the data.
	// const data = theInsights.structure[1].props.steps;
	// Note; setting the data like this due to the change to the json.
	// We may be able to revert to the above if the old style is definitely being used.
	const data = theInsights.structure.filter((str) => {
		if (str.view === 'steps' || str.view === 'questions' || str.view === '') {
			return str.view;
		}
	})[0].props[stepQuestion];

	// Set the conts for the various options.
	const [questions, setQuestions] = useState([]);
	const [choicesSelected, setChoicesSelected] = useState({
		choices: [],
	});
	const [responses, setResponses] = useState([]);
	const [submit, setSubmit] = useState(false);
	const [start, setStart] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [text, setText] = useState('');
	const [progress, setProgress] = useState(0);
	const [number, setNumber] = useState('');
	const [list, setList] = useState(null);
	const [unordered, setUnordered] = useState(null);
	const [skipList, setSkipList] = useState([]);
	const [form, setForm] = useState({});
	// Set the current question number string
	const questionNumber = 'Step' + [currentQuestion + 1];

	// Setting respoonses with empty properties as question and value as empty string
	useEffect(() => {
		setResponses([]);
	}, []);

	if(reset == 'reset'){
		setResponses([]);
	}

	// to go to next question
	const home = () => {
		window.location.reload();
	};

	// to go to next question
	const progressToNextQuestion = () => {
		let maxImages = Math.min(theInsights.structure[0].props.image_to_show, data.length);

		if (currentQuestion < maxImages - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}

		// enable submit button
		if (currentQuestion === maxImages - 1) {
			setSubmit(true);
		}
		// progress bar
		setProgress(prevProgress => {
			const newProgress = prevProgress + progressBarLength;
			return newProgress > 100 ? 100 : newProgress;
		});
	};

	// Next button
	const nextQuestion = () => {
		// Set the current type as a variable.
		let currentType = data[currentQuestion].type;

		// Switch based on lower case to avoid issues.
		switch (currentType.toLowerCase()) {

			// Spot the bot
			case 'spot_bot':
				const spotBotValue = choicesSelected.choices;
				if (choicesSelected.choices.length < 1) {
					// If not enough choices have been selected return false so it doesn't go to the next question
					// alert('More choices required');
					return false;
				}
				responses[currentQuestion] = spotBotValue;
				setResponses({ ...responses });
				break;
			default:
				// Do nothing by default.
		}

		// Progress to the next question, call this after the data above so validation can be done
		progressToNextQuestion();

		// Reset the text, number and choices
		setText('');
		setNumber('');
		setChoicesSelected({
			choices: [],
		});

		// if next question is a form set responses to form
		if (data[(currentQuestion + 1)] && data[(currentQuestion + 1)].type === 'form') {
			setForm(responses[currentQuestion + 1]);
		}
	};

	// progress bar
	const progressIntialVal = 0;
	const maxImages = Math.min(theInsights.structure[0].props.image_to_show, data.length);
	const progressBarLength = (100 / (maxImages - 1));
	useEffect(() => {
		setProgress(progressIntialVal > 100 ? 100 : progressIntialVal < 0 ? 0 : progressIntialVal);
	}, [progressIntialVal]);

	useEffect(() => {
		handleProgressSubmit(questionNumber);
	}, [questionNumber]);

	// set initial values to responses from data
	useEffect(() => {
		// set initial list to order
		if (data[currentQuestion].type === 'order') {
			const ArrayList = data[currentQuestion].answers.map((item) => item.label);
			setUnordered(ArrayList);
		}
		// set initial properties with empty values to form
		if (data[currentQuestion].type === 'form') {
			const isNotEmpty = Boolean(Object.values(responses[currentQuestion]).join(''));
			if (!isNotEmpty) {
				data[currentQuestion].fields.forEach((fields, i) => {
					setForm(form => ({ ...form, [`${fields.label}`]: '' }));
				});
			}
		}
	}, [currentQuestion]);

	// Setting order questions
	useEffect(() => {
		if (responses[currentQuestion] && data[currentQuestion].type === 'order') {
			setList(unordered);
			if (Object.values(responses[(currentQuestion)])[0] != '') { // [TODO] should this be `!==` ?
				setList(Object.values(responses[(currentQuestion)])[0]);
			}
		}
	}, [unordered]);


	return (
		<div className="App">
			{ !submit ? (
				<div className="survey">
					<div className="survey__header">
						<div className="survey__back">
							{/* <button className="plain" onClick={prevQuestion} >Back</button> */}
						</div>
						<div className="survey__name">
							<button className="home" onClick={home}>Home</button>
						</div>
						<div className="survey__skip">
							{/* <button className="plain" onClick={skipQuestion}>Skip</button> */}
						</div>
						<div className="survey__progress">
							<ProgressBar progress={progress} steps={maxImages} responses={responses} />
						</div>
						<img className='logo' src={theInsights.structure[0].props.logo_url} alt={theInsights.structure[0].props.display_name}/>
					</div>

					{(() => {
						// Set the current type as a variable.
						let currentType = data[currentQuestion].type;

						// Switch based on lower case to avoid issues.
						switch (currentType.toLowerCase()) {

							// Spot the bot
							case 'spot_bot':
								return (<SpotBot key={`image-` + currentQuestion} maxImages={maxImages} data={data} currentQuestion={currentQuestion} responses={responses} choicesSelected={choicesSelected} setChoicesSelected={setChoicesSelected}/>);
								break;
							default:
								// Do nothing by default.
						}
					})()}
					<div className="survey__footer">
						<button onClick={nextQuestion}>{theInsights.structure[0].props.continue_label}</button>
					</div>
				</div>)
				: (
					<SubmitPage insights={theInsights} responses= {responses} skipList={skipList} setResponses={setResponses} setSubmit={setSubmit} handleProgressSubmit={handleProgressSubmit} setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} setRestart={setShow}/>
				)
			}
		</div>
	);
}

export default App;
